fieldset {
	padding: 0;
	margin: 0;
	border: 0;
}
.form-actions {
	padding: 1em;
	margin: 0 -5px 5px -5px;
}
/* primeng doesn't provide much form styling. add back in */
input[type="text"], input[type="number"], textarea, select {
	width: 100%;
}
.p-autocomplete, .p-autocomplete .p-autocomplete-multiple-container.p-inputtext{
	width: 100%;
}
.p-autocomplete-token{
	margin: .125em;
}
input[type="text"], input[type="number"] {
	height: 32px;
}

form .p-dropdown{
	width: 100% !important;
	height: 34px;
	padding: 2px;
}
form .p-autocomplete, .p-autocomplete .p-autocomplete-multiple-container.p-inputtext{
	width: 100%;
}

.p-calendar input{
	width: 80%;
}

button.p-button {
	border-radius: 15px;
}

.p-radiobutton-box.p-widget.p-state-default.p-state-active {
	color:#fff;
}

.p-multiselect-item.p-state-highlight label{
	color:#fff;
}


.p-chkbox-box.p-state-active {
	border: #004b87 solid 1px !important;
	background: none !important;
	color: #004b87;
}

.p-calendar input {
	width: 100%;
}

.p-datepicker.p-widget .p-datepicker-calendar td a.p-state-active {
	background-color: #004b87;
	color: #ffffff !important;
}
