/* primeng doesn't have layout helpers. add them here */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.items-center {
  display: flex;
  align-items: center;
}

.layout-portlets-box {
  border: 1px solid #DDD;
  background: #FFF;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.075);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.075);
  margin-top: 1em;
  position: relative;
  overflow: visible;
}

.layout-portlets-box {
  width: 98%;
  padding: 1% 1.5%;
  margin: 1em;
  float: left;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(159, 172, 183, 0.7);
  -moz-box-shadow: 0px 1px 0px 0px rgba(159, 172, 183, 0.7);
  box-shadow: 0px 1px 0px 0px rgba(159, 172, 183, 0.7);
}

.layout-portlets-box {
  border: 2px solid #425968;
  border-radius: 6px;
  box-shadow: none;
  /* margin-bottom: 1.5em; */
  box-sizing: border-box;
}

.label, .badge {
  display: inline-block;
  padding: 2px 4px;
  font-size: 1em;
  font-weight: 700;
  line-height: 14px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #2d96cd;
  font-family: AntennaLight, Arial, Helvetica, sans-serif;
}

.label {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.label-required {
  position: absolute;
  left: 10px;
  top: -10px;
  padding: 5px;
}

.label.label-required {
  padding: 2px 4px;
  top: -13px;
}

.p-multiselect .p-multiselect-label,
.p-outputlabel, .p-radiobutton-label,
.p-multiselect-panel .p-multiselect-item label,
.p-dropdown-panel .p-dropdown-item,
.p-radiobutton-label {
  font-size: 1em;
  color: #333;
  font-family: AntennaLight, Arial, Helvetica, sans-serif;
}

.p-table .p-table-thead > tr > th {
  color: #333;
  font-weight: 700 !important;
  font-family: AntennaLight, Arial, Helvetica, sans-serif;
}

.p-table .p-table-tbody > tr > td,
.p-table .p-table-tfoot > tr > td {
  color: #333;
  font-family: AntennaLight, Arial, Helvetica, sans-serif;
}

.panelDiv .p-widget-header {
  background: #dbdbdb !important;
  color: #333 !important;
}

.p-panel .p-widget-header {
  border-color: #dbdbdb;
}

.p-widget-content {
  border: 1px solid #dbdbdb;
}

a.p-paginator-page.p-paginator-element.p-state-default.p-corner-all.p-state-active {
  color: #fff !important;
}

span.p-menuitem-text {
  color: #fff;
}

.p-growl-item-container.p-state-highlight.p-growl-message-info {
  background-color: #7FBCEC;
  border-color: #7FBCEC;
  color: #0000B2;
  font-weight: bold !important;
}
.p-growl-item-container.p-state-highlight.p-growl-message-warn {
  background-color: #FFE399;
  border-color: #FFE399;
  color: #9B7F1D;
  font-weight: bold !important;
}
.p-growl-item-container.p-state-highlight.p-growl-message-error {
  background-color: #F8B7BD;
  border-color: #F8B7BD;
  color: #AA0000;
  font-weight: bold !important;
}
.p-growl-item-container.p-state-highlight.p-growl-message-success {
  background-color: #B7D8B7;
  border-color: #B7D8B7;
  color: #006E00;
  font-weight: bold !important;
}

.p-dropdown-item.p-state-highlight {
  color: #fff !important;
}

input:disabled {
  opacity: 0.35;
  background-color: #fff;
  border: 1px solid #d6d6d6;
}

span.p-button-icon-left.p-clickable.pi.pi-arrow-circle-right {
  font-size: 19px !important;
}

a.excel-link, a.excel-link:link, a.excel-link:visited {
  background: url("../../../image/icons_sprite.png") no-repeat -2px 0px;
  padding: 1px 0 3px 20px;
  display: inline-block;
  font-family: AntennaLight, Arial, Helvetica, sans-serif;
  cursor: pointer;
  font-size: 12px;
  color: #000 !important;
  font-weight: bold !important;
}

a.print-link, a.print-link:link, a.print-link:visited {
  background: url("../../../image/icons_sprite.png") -2px -240px no-repeat;
  padding: 1px 0 3px 20px;
  display: inline-block;
  font-family: AntennaLight, Arial, Helvetica, sans-serif;
  cursor: pointer;
  font-size: 12px;
  color: #000 !important;
  font-weight: bold !important;
}

#app-header #app-branding h1 {
  margin: 0;
  line-height: 1.5em;
  display: inline-block;
}

#app-header #app-branding {
  flex-wrap: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em 0;
}

#app-header #app-branding #app-icon {
  align-self: center;
  text-align: center;
}

#app-header #app-branding #app-name {
  flex-grow: 1;
  padding: 0 1em;
  align-self: center;
}

#app-header #app-branding #app-login {
  float: right;
  text-align: right;
  align-self: center;
}

#app-header #app-branding #app-icon img {
  display: inline-block;
}

#app-header #app-branding #app-login a {
  margin: 0 0.5em;
}

#app-header #app-branding #app-login a .fa {
  padding-right: 0.25em;
}

#app-footer > div {
  width: 100%;
}

#app-branding #app-login {
  display: block;
}

.sidebar-app-login, .btn-sidebar {
  display: none;
}

html {
  height: auto;
}

body, app-root {
  height: 100%;
}

app-root {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
}

#app-header {
  flex-shrink: 0;
  flex-basis: auto;
  margin: 0 0.5em 1em;
}

@media (min-width: 50px) and (max-width: 767px) {
  .p-menubar, #app-branding #app-login {
    display: none;
  }
  .p-sidebar .p-menubar, .sidebar-app-login, .btn-sidebar {
    display: block;
  }
  .quicklinks-list li a {
    padding: 0.15em;
  }
  #app-footer * {
    text-align: center;
  }
  #app-branding .logo {
    max-width: 90px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 50px) and (max-width: 479px) {
  /*#app-header #app-branding #app-name {
  	text-align: center;
  }*/
  #app-header #app-branding .logo {
    max-width: 95px;
    transition: max-width 0.5s ease-in;
  }
  #app-header #app-branding #app-icon {
    align-self: flex-start !important;
  }
}
@media (min-width: 480px) {
  #app-header #app-branding .logo {
    max-width: 100px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 768px) {
  #app-header #app-branding .logo {
    max-width: 105px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 960px) {
  #app-header #app-branding .logo {
    max-width: 110px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 1024px) and (max-width: 1286px) {
  #app-header #app-branding .logo {
    max-width: 115px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 1287px) {
  #app-header #app-branding .logo {
    max-width: 120px;
    transition: max-width 0.5s ease-in;
  }
}
.p-menubar {
  border: none;
  padding: 0;
  border-radius: 0;
}

.p-menubar ul {
  line-height: 1.5 !important;
}

.p-menubar .p-menubar-root-list {
  margin: 0;
}

.p-menubar .p-menuitem .p-menuitem-link { /* First Level items */
  text-decoration: none;
  padding: 1em !important;
}

.p-menubar .p-submenu-list {
  min-width: 15em;
}

.p-menubar .p-submenu-list .p-menuitem .p-menuitem-link { /* Second Level items */
  padding: 0.5em !important;
}

.p-menubar .p-menu-parent .p-menu-child {
  background: #fff;
}

.p-sidebar {
  padding: 0;
  overflow-y: auto;
}

.p-sidebar .p-menubar .p-menubar-root-list, .p-sidebar .p-menubar .p-menubar-root-list > .p-menuitem {
  display: block;
  width: 100%;
}

.p-sidebar .p-menubar .p-submenu-list, .p-sidebar .p-menubar .p-menu-parent .p-menu-child {
  position: static;
  width: 100%;
}

.p-sidebar-close {
  z-index: 10000;
  position: absolute;
  right: 0;
}

.btn-sidebar button {
  padding: 0.25em 0.5em;
}

.p-menubar .p-menubar-custom {
  float: none;
  margin: 0;
  padding: 0;
}

.p-menubar .p-menubar-custom .sidebar-app-login a {
  display: block;
  width: 100%;
  padding: 0.75em;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0.5em;
}

p {
  margin: 0 0 10px;
}

hr {
  margin-bottom: 1em;
}

h1,
.h1 {
  margin-bottom: 10px;
}

h2,
.h2 {
  margin-bottom: 10px;
}

#app-content {
  height: auto;
  flex: 1 0 auto;
  margin: 0 0 1em;
}

#app-footer {
  flex-shrink: 0;
  flex-basis: auto;
  width: 100%;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

.form-actions {
  padding: 1em;
  margin: 0 -5px 5px -5px;
}

/* primeng doesn't provide much form styling. add back in */
input[type=text], input[type=number], textarea, select {
  width: 100%;
}

.p-autocomplete, .p-autocomplete .p-autocomplete-multiple-container.p-inputtext {
  width: 100%;
}

.p-autocomplete-token {
  margin: 0.125em;
}

input[type=text], input[type=number] {
  height: 32px;
}

form .p-dropdown {
  width: 100% !important;
  height: 34px;
  padding: 2px;
}

form .p-autocomplete, .p-autocomplete .p-autocomplete-multiple-container.p-inputtext {
  width: 100%;
}

.p-calendar input {
  width: 80%;
}

button.p-button {
  border-radius: 15px;
}

.p-radiobutton-box.p-widget.p-state-default.p-state-active {
  color: #fff;
}

.p-multiselect-item.p-state-highlight label {
  color: #fff;
}

.p-chkbox-box.p-state-active {
  border: #004b87 solid 1px !important;
  background: none !important;
  color: #004b87;
}

.p-calendar input {
  width: 100%;
}

.p-datepicker.p-widget .p-datepicker-calendar td a.p-state-active {
  background-color: #004b87;
  color: #ffffff !important;
}

.export-btn-group {
  float: right;
}

.export-btn-group > button {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 0;
}

.p-button-link {
  padding: 0 !important;
}

table .p-widget-content td:first-child {
  overflow: visible;
}