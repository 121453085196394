@media (min-width: 50px) and (max-width: 767px) {
	.p-menubar, #app-branding #app-login {
		display: none;
	}
	.p-sidebar .p-menubar, .sidebar-app-login, .btn-sidebar {
		display: block;
	}
	.quicklinks-list li a {
		padding: .15em;
	}
	#app-footer * {
		text-align: center;
	}
	#app-branding .logo {
		max-width: 90px;
		transition: max-width .5s ease-in;
	}
}

@media (min-width: 50px) and (max-width: 479px) {
	/*#app-header #app-branding #app-name {
		text-align: center;
	}*/
	#app-header #app-branding .logo {
		max-width: 95px;
		transition: max-width .5s ease-in;
	}
	#app-header #app-branding #app-icon {
		align-self: flex-start !important;
	}
}

@media (min-width: 480px) {
	#app-header #app-branding .logo {
		max-width: 100px;
		transition: max-width .5s ease-in;
	}
}

@media (min-width: 768px) {
	#app-header #app-branding .logo {
		max-width: 105px;
		transition: max-width .5s ease-in;
	}
}

@media (min-width: 960px) {
	#app-header #app-branding .logo {
		max-width: 110px;
		transition: max-width .5s ease-in;
	}
}

@media (min-width: 1024px) and (max-width: 1286px) {
	#app-header #app-branding .logo {
		max-width: 115px;
		transition: max-width .5s ease-in;
	}
}

@media (min-width: 1287px) {
	#app-header #app-branding .logo {
		max-width: 120px;
		transition: max-width .5s ease-in;
	}
}