@import "variables";


@media (min-width: 50px) and (max-width: 479px) {
	#app-header #app-branding #app-name h1 {
		font-size: 18px;
		transition: $app-header-transition;
	}
}

@media (min-width: 480px) {
	#app-header #app-branding #app-name h1 {
		font-size: 20px;
		transition: $app-header-transition;
	}
}

@media (min-width: 768px) {
	#app-header #app-branding #app-name h1 {
		font-size: 22px;
		transition: $app-header-transition;
	}
}

@media (min-width: 960px) {
	#app-header #app-branding #app-name h1 {
		font-size: 24px;
		transition: $app-header-transition;
	}
}

@media (min-width: 1024px) and (max-width: 1286px) {
	#app-header #app-branding #app-name h1 {
		font-size: 26px;
		transition: $app-header-transition;
	}
}

@media (min-width: 1287px) {
	#app-header #app-branding #app-name h1 {
		font-size: 28px;
		transition: $app-header-transition;
	}
}

/*@media (max-width: 479px) {
	.ui-table table {
		table-layout: auto !important;
	}
}*/

@media (max-width: 479px) and (min-width: 50px) {
	.p-table table {
		table-layout: auto !important;
	}
}