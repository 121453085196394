@import "variables";

.p-button-link {
	background:none!important;
	border:none;
	outline: none;
	cursor:pointer;
	color: $ui-menuitem-link-color;
	text-decoration: none;
	//font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	font-family:AntennaLight,Arial,Helvetica,sans-serif;
	line-height: 1.5;
	font-size: 16px;
}

.p-button-link:hover {
	color: $btn-sidebar-bg;
}
