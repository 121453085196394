@import "datatable";
@import "fonts";
@import "header";
@import "responsive";
@import "sidebar";
@import "type";
@import "variables";
@import "form";
@import "panels";
@import "buttons";

$headerFontColor:#004b87;
$menuBgcolor:#004b87;
$menuBgColor2:#393D40;
$blueThemeColor:#004b87;
$btncolor:#004b87;


.header_fontcolor,
.header_style  {
	color:$headerFontColor;
}

.p-menubar {
	background-color: $menuBgcolor;
}

body .p-menu .p-menuitem-link:hover,
body .p-menubar .p-menuitem-link:hover,
body .p-tieredmenu .p-menuitem-link:hover,
body .p-contextmenu .p-menuitem-link:hover,
body .p-megamenu .p-menuitem-link:hover,
body .p-slidemenu .p-menuitem-link:hover,
body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link{
	background-color:$menuBgColor2 !important;
	color:#fff !important;
}

.p-button {
	border: 1px solid $btncolor !important;
	background: $btncolor !important;
	height: 30px;
}

.p-widget-header :enabled.p-button:hover,
.p-widget-header .p-button:focus,
.p-widget-content :enabled.p-button:hover,
.p-widget-content .p-button:focus,
:enabled.p-widget.p-button:hover,
.p-widget.p-button:focus, :enabled.p-button:hover,
.p-button:focus {
	border: 1px solid $blueThemeColor !important;
	background: $blueThemeColor !important;
}

.header_container {
	border-bottom: 3px solid $blueThemeColor;
}
.p-table .p-table-thead > tr > th{
	background: $blueThemeColor !important;
	border-color: $blueThemeColor !important;
	color: #fff !important;
}
.p-table .p-table-tbody > tr > td {
	border:solid 1px #DBDBDB !important;
}
.header_color{
	border-bottom:solid black 1px;
	font-size:16px;
	color:#2D98CD;
	font-weight: bold;
}

.p-widget, .p-widget .p-widget {
	font-family: $fontFamilyAntennaLight !important;
}

a {
	color: $blueThemeColor !important;
	font-weight: normal !important;
}
a:hover, a:active, a:focus  {
	color: blue !important;
	cursor: pointer !important;
}
.panelDiv .p-widget-header {
	background: $blueThemeColor !important;
	color:#fff !important;
}
