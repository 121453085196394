/* primeng doesn't have layout helpers. add them here */
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.items-center{
	display: flex;
	align-items: center;
}

.layout-portlets-box {
	border: 1px solid #DDD;
	background: #FFF;
	-webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.075);
	-moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.075);
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.075);
	margin-top: 1em;
	position: relative;
	overflow: visible;
}

.layout-portlets-box {
	width: 98%;
	padding: 1% 1.5%;
	margin: 1em;
	float: left;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-box-shadow: 0px 1px 0px 0px rgba(159,172,183,0.7);
	-moz-box-shadow: 0px 1px 0px 0px rgba(159,172,183,0.7);
	box-shadow: 0px 1px 0px 0px rgba(159,172,183,0.7);
}

.layout-portlets-box {
	border: 2px solid #425968;
	border-radius: 6px;
	box-shadow: none;
	/* margin-bottom: 1.5em; */
	box-sizing: border-box;
}

.label, .badge {
	display: inline-block;
	padding: 2px 4px;
	font-size: 1em;
	font-weight: 700;
	line-height: 14px;
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	white-space: nowrap;
	vertical-align: baseline;
	background-color: #2d96cd;
	//font-family:"Trebuchet MS",Arial,Helvetica,sans-serif;
	font-family:AntennaLight,Arial,Helvetica,sans-serif;
}


.label {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
.label-required {
	position: absolute;
	left: 10px;
	top: -10px;
	padding: 5px;
}

.label.label-required {
	padding: 2px 4px;
	top: -13px;
}

.p-multiselect .p-multiselect-label,
.p-outputlabel, .p-radiobutton-label,
.p-multiselect-panel .p-multiselect-item label,
.p-dropdown-panel .p-dropdown-item,
.p-radiobutton-label{
	font-size: 1em;
	color: #333;
	//font-weight: 700 !important;
	//font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	font-family:AntennaLight,Arial,Helvetica,sans-serif;
}

.p-table .p-table-thead > tr > th{

	color: #333;
	font-weight: 700 !important;
	//font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	font-family:AntennaLight,Arial,Helvetica,sans-serif;
}

.p-table .p-table-tbody > tr > td,
.p-table .p-table-tfoot > tr > td{
	color: #333;
	//font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	font-family:AntennaLight,Arial,Helvetica,sans-serif;
}

.panelDiv .p-widget-header {
	background: #dbdbdb !important;
	color: #333 !important;
}

.p-panel .p-widget-header{
	border-color: #dbdbdb;
}

.p-widget-content {
	border: 1px solid #dbdbdb;
}

a.p-paginator-page.p-paginator-element.p-state-default.p-corner-all.p-state-active {
	color: #fff !important;
}

span.p-menuitem-text {
	color: #fff;
}

.p-growl-item-container.p-state-highlight {
	&.p-growl-message-info {
		background-color: #7FBCEC;
		border-color :#7FBCEC;
		color:#0000B2;
		font-weight: bold !important;
	}

	&.p-growl-message-warn {
		background-color: #FFE399;
		border-color :#FFE399;
		color:#9B7F1D;
		font-weight: bold !important;
	}

	&.p-growl-message-error {
		background-color: #F8B7BD;
		border-color :#F8B7BD;
		color:#AA0000;
		font-weight: bold !important;
	}

	&.p-growl-message-success {
		background-color: #B7D8B7;
		border-color :#B7D8B7;
		color:#006E00;
		font-weight: bold !important;
	}
}

.p-dropdown-item.p-state-highlight {
	color:#fff !important;
}
input:disabled {
	opacity: 0.35;
	background-color: #fff;
	border: 1px solid #d6d6d6;
}

span.p-button-icon-left.p-clickable.pi.pi-arrow-circle-right {
	font-size: 19px !important;
}

a.excel-link, a.excel-link:link, a.excel-link:visited {
	background: url('../../../image/icons_sprite.png') no-repeat -2px 0px;
	padding: 1px 0 3px 20px;
	display: inline-block;
	//font-family: "Trebuchet MS", Arial, Helvetica, sans-serif !important;
	font-family:AntennaLight,Arial,Helvetica,sans-serif;
	cursor: pointer;
	font-size: 12px;
	color: #000 !important;
	font-weight: bold !important;
}
a.print-link, a.print-link:link, a.print-link:visited {
	background: url('../../../image/icons_sprite.png') -2px -240px no-repeat;
	padding: 1px 0 3px 20px;
	display: inline-block;
	//font-family: "Trebuchet MS",Arial,Helvetica,sans-serif!important;
	font-family:AntennaLight,Arial,Helvetica,sans-serif;
	cursor: pointer;
	font-size: 12px;
	color: #000 !important;
	font-weight: bold !important;
}