
.form-actions {
	border-top: 1px solid #e5e5e5;
}

label, legend {
	font-weight: 700;
}

fieldset label {
	font-weight: 400;
}

textarea {
	font-family: inherit;
}

sup {
	vertical-align: super;
	font-size:smaller;
	color:red;
}