body {
   font-family: AntennaLight,Arial,Helvetica,sans-serif;
}
.header_fontcolor {
   margin:0px;
}

.font_style {
   font-weight: bold;
}

.p-menubar.p-widget,
.p-panel.p-widget {
   margin:0;
}

.p-panel-titlebar {
   font-weight: bold !important;
   font-size:16px !important;
}

.p-button-icon-only .fa, .p-button-text-icon-left .fa, .p-button-text-icon-right .fa {
   font-size:14px !important;
}


.p-table .p-sortable-column .p-sortable-column-icon {
	color: #fff !important;
}

.header_style {
   margin:0px;
   font-size:24px;
   font-weight: bold;
}
.container {
   border:none !important;
   margin:0 5%;
}

.header_container {
   padding-bottom:7px;
}

.required ::after {
   content: '*';
   color:red;
   font-size: 12px;
}

.panelDiv .ui-panel.ui-widget {
   margin: 0 !important;
}

.panelDiv .ui-widget-header {
   text-align: center !important;
}

.header_size h2{
   font-size: 1.5em;
}

.header_banner
{
   border:solid #27405f 1px;
   color: #fff;
   background-color: #27405f;
   padding: 1px;
   margin: 0 25px 0 15px;
   line-height: 1;
}
.button_prime{
   background-color: #27405f; 
   height: 25px;
}

.navigator{
   background-color: #27405f;
}
#bars.ui-button .ui-button-text {
   display: none !important;
}




.fontStyle {
   color: #004b87;
   font-size: 16px;
   font-weight: bold;
}

.fontStyle1 {
   color: red;
}

.btnStyle.ui-button {
   border: 1px solid #007ad9 !important;
   background: #007ad9 !important;
}
.btnStyle.ui-button:enabled:hover {
   background-color: #005b9f !important;
   color: #ffffff;
   border-color: #005b9f !important;;
}

.btnStyleUser.ui-button {
   border: 1px solid #34A835 !important;
   background: #34A835 !important;
}
.btnStyleUser.ui-button:enabled:hover {
   background-color: #107D11 !important;
   color: #ffffff;
   border-color: #107D11 !important;;
}

.alert-danger {
   color:red;
   padding-top:10px;
}

.header_bgstyle{
   display: flex;
   justify-content: center;
   align-items: flex-start;
}

.header_bgstyle .header1{
   flex-shrink:1;
   min-width: 100px;
   font-weight: bold;
}

.header_bgstyle .header2{
   flex-grow: 2;
}

.div-grid div {
   border-right: 2px solid #fff;
   align-items: stretch;
}

.bgcolor{
   background-color: #27405f;
   color:#fff;
}

.odd{
   background-color: #d6e1f5;
}

.even{
   background-color: #e3f1f8;
}

.alpha-color{
   color: red !important;
}

.numeric-color{
   color: blue !important;
}


@media print
{
   .noprintpage{
      display: none;
   }
}
.p-paginator .p-paginator-page {
   width: 3em !important;
}
/* .p-datatable .p-paginator-bottom  
	{
    border-width: 1px 0 6px 0;
    border-radius: 0;
    size: 1px;
    background-color: #dbdbdb !important;
    padding: 1px;
} */

.btnStyleFcic.p-button {
   border: 1px solid #007ad9 !important;
   background: #FFFFFF !important;
   color: #007ad9;
}

.btnStyleFcic.p-button:enabled:hover {
   border: 1px solid #007ad9 !important;
   background: #dcdcdc !important;
   color: #007ad9;
}

/*
.btnStyleFcic.ui-button {
   border: 1px solid #27405f !important;
   background: #FFFFFF !important;
   color: #27405f;
}

.btnStyleFcic.ui-button:enabled:hover {
   border: 1px solid #27405f !important;
   background: #eeeeee !important;
   color: #27405f;
}
*/

span.p-datepicker-month {
   padding-right: 10px !important;
}

.p-progress-spinner-circle {
   stroke: red;
   stroke-dasharray: 89, 200;
   stroke-dashoffset: -35px;
}


.fcicbtnStyle1 {
   height: auto;
   overflow-y: auto;
}

.fcicbtnStyle2 {
   height: 200px;
   overflow-y: auto;
}

.p-multiselect-header .p-multiselect-filter-container {
	width: 100% !important;
}

.p-radiobutton-icon {
	font-size: 9px !important;
}

.p-radiobutton-box {
	width: 1em;
	height: 1em;
	border: 1px solid #999 !important;
}

.p-radiobutton-box.p-widget.p-state-default.p-state-active {
	color: #000 !important;
}

.p-radiobutton-box.p-state-active {
	border: 1px solid #156090;
	background: #fff !important;
}

.p-table .p-table-thead > tr > th {
	border-color: #DBDBDB !important;
}

#tbl_programSummaryFimps a ,
#tbl_detailedSummaryFimps a{
	text-decoration: underline;
}


.app-login {
	font-size: 14px !important;
	float: right !important;
	text-align: right;
	line-height: 2.75;
	color: #20afcc !important;
	/*margin-right: 15px;*/
	display: block;
}

#drp_payeeCodeFimps .p-listbox-list {
	height: 85px !important;
	overflow: auto !important;
}

#mul_monthsFimps .p-listbox-list {
	height: 330px !important;
	overflow: auto !important;
}
#adjustmentTable th, #adjustmentTable td {
	border: 1px solid #ddd;
}

.p-autocomplete .p-inputtext{
	border: solid #767676 1px;
	height: 32px !important;
}

.p-state-default {
	border: 1px solid #767676;
}

/*.disableSpinner i.ui-autocomplete-loader.pi.pi-spinner.pi-spin {*/
/*	display: none !important;*/
/*}*/
.p-dialog .p-dialog-header {
   border-bottom: 1px solid #dee2e6;
   background: linear-gradient(to bottom,#f6f7f9 0,#ebedf0 100%);
   color: #1b1d1f;
   padding: 1rem;
   border-top-right-radius: 3px;
   border-top-left-radius: 3px;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
   color: #1b1d1f;
}



.p-dropdown .p-dropdown-trigger {
   background: transparent;
   color: #4d4d4d;
   width: 2.357rem;
   border-top-right-radius: 3px;
   border-bottom-right-radius: 3px;
   border-left: 1px solid #6e6e6e;
}

.p-dropdown .p-dropdown-label {
   background: transparent;
   border: 0 none;
   height: 32px;
   padding: 4px 2px;
}


.p-multiselect .p-multiselect-trigger {
   background: transparent;
   color: #4d4d4d;
   width: 2.357rem;
   border-top-right-radius: 3px;
   border-bottom-right-radius: 3px;
   border-left: 1px solid #6e6e6e;
}

.p-multiselect .p-multiselect-label {
   background: transparent;
   border: 0 none;
   height: 32px;
   padding: 4px 2px;
}