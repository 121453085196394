@import "variables";


.p-menubar {
	background: $ui-table-bg;
}
.p-state-active {
	background: #428bca;
}

.p-menubar .p-menuitem .p-menuitem-link { /* First Level items */
	color: $white;
	background: none !important;
	border-radius: 0;
}
.p-menubar .p-menuitem .p-menuitem-link:hover, .p-menubar .p-menubar-custom .sidebar-app-login a:hover{
	background-color: $sidebar-app-login-bg !important;
}
.p-menubar .p-menuitem .p-menuitem-link:active, .p-menubar .p-menubar-custom .sidebar-app-login a:active{
	background-color: $sidebar-app-login-bg !important;
	box-shadow: inset 0 0 7px $sidebar-app-login-color;
}
.p-menubar .p-menuitem .p-menuitem-link.p-state-active{
	background-color: $btn-sidebar-bg !important;
	box-shadow: inset 0 0 7px #222;
	transition: background-color .5s ease-in;
}
.p-menubar .p-menuitem.p-menuitem-active, .p-menubar .p-menuitem.p-menuitem-active .p-menuitem-link{ /* First Level items (Active) */
	background: none;
}
.p-menubar .p-submenu-list{
	background: $white;
}
.p-menubar .p-submenu-list .p-menuitem .p-menuitem-link { /* Second Level items */
	color: $ui-menuitem-link-color;
	background: $white;
}
.p-menubar .p-submenu-list .p-menuitem.p-menuitem-active .p-menuitem-link { /* Second Level items (Active) */
	background: $app-footer-bg !important;
	color: $ui-menuitem-link-color;
}

.p-menubar .p-menu-parent .p-menu-child{
	background: $white;
}
.p-sidebar{
	border: 0;
}
.p-sidebar .p-menubar .p-menuitem .p-menuitem-link{
	border-bottom: 1px solid $ui-menubar-border;
}

.p-sidebar .p-menubar .p-submenu-list .p-menuitem .p-menuitem-link{
	border-bottom: 1px solid $ui-sidebar-bg;
}
.p-sidebar-close{
	color: $ford-blue;
	background: $ui-sidebar-bg;
}

.btn-sidebar button{
	background: $ui-sidebar-bg;
	border: 0;
	outline: none;
	font-size: 1.5em;
	color: $white;
	border-radius: 20%;
}
.p-menubar .p-menubar-custom .sidebar-app-login a{
	color: $white !important;
	background: none;
	border-radius: 0;
	border-bottom: 1px solid $ui-menubar-border;
}
.p-menubar .p-menubar-custom .sidebar-app-login a:hover, .p-menubar .p-menubar-custom .sidebar-app-login a:active, .p-menubar .p-menubar-custom .sidebar-app-login a:focus{
	color: $white;
}
.p-menubar .p-menubar-custom .sidebar-app-login .fa{
	color: $white;
}
